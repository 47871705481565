
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ShopItem from '@/components/ShopItem.vue';
import IProduct from '@/module/main/dto/IProduct';
import ICategory from '@/module/main/dto/ICategory';

@Component({
  components: {
    ShopItem,
  },
})
export default class CategoryShow extends Vue {
  @Prop() private products!: IProduct[];
  @Prop() private category!: ICategory;
  @Prop() private windowWidth?: number;

  private categoryProducts: IProduct[] = this.products;

  private productSet: IProduct[][] = [];

  private quartersOfCarousel: IProduct[][] = this.getQuarters(this.categoryProducts || []);

  private displayNextProducts = this.categoryProducts ? this.categoryProducts.length > 0 : false;
  private displayArrows = false;

  private prepareCarusel(): void {
    if (this.categoryProducts.length > 0) {
      this.displayNextProducts = this.categoryProducts.length > 0;

      for (let i = 0; i < 4; i++) {
        const set = this.categoryProducts.slice(4 * i, 4 + 4 * i);
        if (set.length > 0)
          this.productSet.push(set);
      }

      this.quartersOfCarousel = this.getQuarters(this.categoryProducts);
      if (this.categoryProducts.length > 1) {
        this.displayArrows = true;
      }
    } else {
      this.displayNextProducts = false;
      this.categoryProducts = [];
    }
  }

  @Watch('products')
  private onProductsChange(val: IProduct[]): void {
    if (val) {
      this.categoryProducts = val;
      this.prepareCarusel();
    }
  }

  private slide = 0;
  private sliding = false;

  private onSlideStart(): void {
    this.sliding = true;
  }

  private onSlideEnd(): void {
    this.sliding = false;
  }

  private getQuarters(products: IProduct[]): any[] {
    const arrayOfPairs = [];
    if (products.length > 0) {
      let i;
      for (i = 0; i < (products.length / 2); i++) {
        const first = products[i * 2];
        const second = products[i * 2 + 1];
        const arr = [first, second];
        arrayOfPairs.push(arr);
      }
      return arrayOfPairs;
    }
    return [];
  }

  private redirectToProduct(productId: number): void {
    this.$emit('productRedirect', productId);
  }

  created() {
    this.prepareCarusel();
  }
}
