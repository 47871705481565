

import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import SearchResults from '../../../components/SearchResults.vue';
import NoOffer from '../../../components/NoOffer.vue';
import HomeLayout from '../../../app/layouts/HomeLayout.vue';
import ICategory from '@/module/main/dto/ICategory';
import Api from '@/module/api/service/Api';
import CategoryShow from '@/components/CategoryShow.vue';
import IProducts from '@/module/main/dto/IProducts';
import categoryIconList from '@/module/main/service/CategoryIconList';
import ICategoryIcon from '@/module/main/service/ICategoryIcon';

@Component({
  components: {
    HomeLayout,
    CategoryShow,
    SearchResults,
    NoOffer,
  },
})
export default class Home extends Vue {
  private windowWidth = window.innerWidth;
  private categoriesToShow: any[] = [];
  private searching = false;
  private searchQuery = '';
  private searchResults: IProducts | null = null;
  private searchNotSuccessful = false;
  private isLoading = true;
  private isCategories = true;

  private getCategoryIcon(categoryText: string): ICategoryIcon | null {
    const icon: ICategoryIcon[] = categoryIconList.filter(
      e => e.categoryNameCzech === categoryText.toLowerCase()
        || e.categoryNameSlovak === categoryText.toLowerCase());
    return icon ? icon[0] : null;
  }

  private get mainCategories(): ICategory[] {
    return this.$store.state.categories?.getAll()?.filter(this.isMainCategory) ?? [];
  }

  private get caruselData(): any[] {
    return this.categoriesToShow;
  }

  private isMainCategory(category: any): boolean {
    return typeof category.parentCategoryId !== 'number'
      &&  (typeof category.types === 'undefined' || !category.types.includes('PROMO'));
  }

  private collectSearchResults(searching: boolean, data: IProducts, query: string) {
    this.searchQuery = query;
    this.searching = searching;
    if (data?.data.length > 0) {
      this.searchResults = data;
      this.searchNotSuccessful = false;
    } else {
      this.searchNotSuccessful = true;
    }
  }

  private loadCarouselProducts(index: number): void {
    this.isCategories = true;
    if (this.mainCategories.length > index
      && this.isMainCategory(this.mainCategories[index]) && this.categoriesToShow.length < 3) {
      const category = this.mainCategories[index];
      const showCategory: any = {
        category,
        products: [],
      }
      this.categoriesToShow.push(showCategory);
      Api.products(category.id, 1,16).then((response) => {
        if (response && response.data.length > 0) {
          showCategory.products = response.data;
          this.isLoading = false;
        }
        this.loadCarouselProducts(++index);
      });
    } else {
      scroll(0,0);
    }
  }

  private noCategoryHandle(): void {
    this.isLoading = false;
    this.isCategories = false;
  }

  private onCategoryData(data: any | null) {
    if (data !== null  && typeof data.name === 'string' && data.name.length > 0 ) {
      this.loadCarouselProducts(0);
    } else {
      this.noCategoryHandle();
    }
  }

  public created(): void {
    this.$store.commit('setBreadcrumbs', []);
    localStorage.removeItem('hc-zdc:catstate');
    setTimeout(()=>scroll(0,0), 300);
  }
}
